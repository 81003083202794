import { useState, useEffect } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Group } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { adminCarModelsService } from "services/adminCarModelsService";

import FbxModel from "./FbxModel";
import { saveAs } from "file-saver";
import modalStyles from "components/ModalComponent/styles";

const ModalManage3dFile = ({
  open,
  fileName,
  sourceFile,
  handleCloseModal,
  removeModel,
  showRemoveButton,
}: {
  open: boolean;
  fileName: string | null;
  sourceFile: File | null;
  handleCloseModal: () => void;
  removeModel: (fileName: string) => void;
  showRemoveButton: boolean;
}) => {
  const [model, setModel] = useState<Group | null>(null);

  const resetModel = () => {
    setModel(null);
  };

  const downloadModel = () => {
    if (sourceFile) {
      saveAs(sourceFile, fileName ?? "model.fbx");
    }
  };

  const deleteModel = async () => {
    if (fileName) {
      await adminCarModelsService.delete3dModel(fileName);
      removeModel(fileName);
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (!open) {
      resetModel();
    } else if (sourceFile) {
      loadFBXModel(sourceFile);
    }
  }, [open]);

  const loadFBXModel = (file: File) => {
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target?.result) {
        const loader = new FBXLoader();
        loader.load(
          event.target.result as string,
          loadedModel => {
            setModel(loadedModel);
          },
          undefined,
          error => {
            console.error("Error loading '.fbx' model:", error);
          },
        );
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="model-modal-title"
        aria-describedby="model-modal-description"
        disableScrollLock
      >
        <Box
          sx={{
            ...modalStyles,
          }}
          component="section"
        >
          {model && <FbxModel model={model} />}
          <div>
            {model && (
              <div>
                <>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ my: 2, width: "100%" }}
                    onClick={downloadModel}
                  >
                    Downlaod
                  </Button>
                  {showRemoveButton && (
                    <Button sx={{ width: "100%" }} onClick={deleteModel}>
                      Delete
                    </Button>
                  )}
                </>
              </div>
            )}
            <Button sx={{ width: "100%" }} onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalManage3dFile;
