import { Box, Button, Modal, Typography } from "@mui/material";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import {
  FinesGroupedByCamera,
  RemoveFineLocationRequest,
} from "services/borbalo-main.service";
import { adminFinesService } from "services/fines";

const DeleteModal = ({
  open,
  toggleModal,
  requestData,
}: {
  open?: FinesGroupedByCamera | null;
  toggleModal: () => void;
  requestData: (item: FinesGroupedByCamera) => void;
}) => {
  const deleteAsync = useAsync<void, any>();

  const handleAddDescription = () => {
    if (open) {
      deleteAsync.run(
        adminFinesService.removeLocation(
          new RemoveFineLocationRequest({
            locationFromImageLatin: open.locationFromImageLatin,
            region: open.region,
            municipality: open.municipality,
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (deleteAsync.isSuccess && open) {
      requestData(open);
      toggleModal();
      deleteAsync.reset();
    }
  }, [deleteAsync.isSuccess]);

  return (
    <Modal
      open={!!open}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box
        sx={{
          ...style,
        }}
        component="section"
      >
        <Typography variant="h4" mb={2}>
          Delete Fine Location: "{open?.locationFromImageLatin}"?
        </Typography>

        <Button
          sx={{
            width: "100%",
            mb: 1.6,
          }}
          onClick={handleAddDescription}
        >
          Delete Fine Location
        </Button>
        <Button
          sx={{
            width: "100%",
          }}
          onClick={toggleModal}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default DeleteModal;
