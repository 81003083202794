import { useState, ChangeEvent, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { TextField, Box, Button, Modal } from "@mui/material";
import { Group } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { adminCarModelsService } from "services/adminCarModelsService";

import FbxModel from "../Cars3dModels/FbxModel";
import modalStyles from "components/ModalComponent/styles";

const DropzoneArea = ({
  onDrop,
}: {
  onDrop: (acceptedFiles: File[]) => void;
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: { "model/*": [".fbx"] },
    onDrop,
  });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          height: "510px",
          alignContent: "center",
        }}
      >
        <input {...getInputProps()} />
        <h3>Drag and drop file here or click to select</h3>
      </div>
    </div>
  );
};

const ModalUpload3dFiles = ({
  open,
  handleCloseModal,
  refreshPage,
  initMake,
  initModel
}: {
  open: boolean;
  handleCloseModal: () => void;
  refreshPage: () => void;
  initMake: string | null;
  initModel: string | null;
}) => {
  const [model3d, setModel3d] = useState<Group | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [year, setYear] = useState<string>("");
  const [make, setMake] = useState<string>(initMake ?? "");
  const [model, setModel] = useState<string>(initModel ?? "");
  const [error, setError] = useState("");

  useEffect(() => {
    setMake(initMake ?? "");
    setModel(initModel ?? "");
  }, [initMake, initModel]);

  const resetModel = () => {
    setFile(null);
    setModel3d(null);
  };

  const saveModel = () => {
    if(year){
      const yearNum = Number(year);
      if (yearNum >= 1900 && yearNum <= 2100 && make && model) {
        adminCarModelsService.upload3d(make, model, yearNum, {data: file, fileName: file?.name ?? "model.fbx"})
        .then(e=>{
          handleCloseModal();
          refreshPage();
        });
        return;
      }
    }
    setYearInputError();
  };

  useEffect(() => {
    if (!open) {
      resetModel();
    }
  }, [open]);

  const setYearInputError = () => {
    setError("Please enter a valid year between 1900 and 2100.");
  }
  const handleFileDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[0];

      loadFBXModel(uploadedFile);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];

      loadFBXModel(uploadedFile);
    }
  };

  const loadFBXModel = (file: File) => {
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target?.result) {
        const loader = new FBXLoader();
        loader.load(
          event.target.result as string,
          loadedModel => {
            setFile(file);
            setModel3d(loadedModel);
          },
          undefined,
          error => {
            console.error("Error loading '.fbx' model:", error);
          },
        );
      }
    };
    reader.readAsDataURL(file);
  };

  const closeModal = () => {
    setYear("");
    handleCloseModal();
  }

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setYear(event.target.value);
    setError("");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="model-modal-title"
        aria-describedby="model-modal-description"
        disableScrollLock
      >
        <Box
          sx={{
            ...modalStyles,
          }}
          component="section"
        >
          {!model3d ? (
            <DropzoneArea onDrop={handleFileDrop} />
          ) : (
            <FbxModel model={model3d} />
          )}

          <div>
            {!model3d ? (
              <Button
                variant="contained"
                component="label"
                sx={{ my: 2, width: "100%" }}
              >
                Select file
                <input
                  type="file"
                  hidden
                  accept=".fbx"
                  onChange={handleFileChange}
                />
              </Button>
            ) : (
              <>
                <TextField
                  label="Make"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={make}
                  inputProps={{ maxLength: 100 }}
                  sx={{ my: 2 }}
                  onChange={e => setMake(e.target.value)}
                />
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={model}
                  inputProps={{ maxLength: 100 }}
                  sx={{ my: 2 }}
                  onChange={e => setModel(e.target.value)}
                />
                <TextField
                  label="Year"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={year}
                  onChange={handleYearChange}
                  inputProps={{ min: 1900, max: 2100, inputMode: "numeric", pattern: "[0-9]*" }}
                  sx={{ my: 2 }}
                  error={!!error}
                  helperText={error}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{ my: 2, width: "100%" }}
                   onClick={saveModel}
                >
                  Save car 3D model
                </Button>
                <Button sx={{ width: "100%" }} onClick={resetModel}>
                  Upload new model
                </Button>
              </>
            )}
            <Button sx={{ width: "100%" }} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalUpload3dFiles;
