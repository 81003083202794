import { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";

import useModal from "hooks/useModal";
import {
  AdminBorbaloCarModel,
  PagedListOfAdminBorbaloCarModel,
} from "services/borbalo-main.service";
import { adminCarModelsService } from "services/adminCarModelsService";
import MyTable from "components/MyTable";

import InvoiceSentIcon from "pages/ProvidersInvoices/InvoiceSentIcon";
import ModalUpload3dFiles from "./ModalUpload3dFiles";
import ModalManage3dFile from "pages/Cars3dModels/ModalManage3dFile";
import { useLoad3dModel } from "pages/Cars3dModels/useLoad3dModel";

const seletorData = [
  {
    value: false,
    label: "All cars",
  },
  {
    value: true,
    label: "Cars without 3d model",
  },
];

const BorbaloCars = ({ title }: { title: string }) => {
  const modalUpload3dFiles = useModal();
  const modalManage3dFiles = useModal();

  const { file, load3dModel } = useLoad3dModel();
  const [fileName, setFileName] = useState<string | null>(null);
  const [carMake, setCarMake] = useState<string | null>(null);
  const [carModel, setCarModel] = useState<string | null>(null);
  const [carsData, setCarsData] = useState<PagedListOfAdminBorbaloCarModel>(
    new PagedListOfAdminBorbaloCarModel(),
  );
  const [filterType, setFilterType] = useState<boolean>(seletorData[0].value);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    adminCarModelsService.listOfBorbaloModels(filterType, 0).then(e => {
      if (e) {
        setCarsData(e);
      }
    });
  };

  useEffect(() => {
    if (file) {
      modalManage3dFiles.handleOpenModal();
    }
  }, [file]);

  const dataInfo =
    carsData?.entities?.map(item => [
      item.make,
      item.model,
      item.bodyTypes.join(", "),
      item.yearFrom,
      item.yearTo,
      item.count,
      <InvoiceSentIcon sent={item.model3d != null} />,
      <>
        {item.model3d != null ? (
          <Button onClick={() => onViewPress(item)}>View</Button>
        ) : (
          <Button onClick={() => onUploadPress(item)}>Upload</Button>
        )}
      </>,
    ]) ?? [];

  const tableData = [
    [
      "Make",
      "Model",
      "Body types",
      "Year from",
      "Year to",
      "Count",
      "3d model exists",
      "",
    ],
    ...dataInfo,
  ];

  const onUploadPress = (item: AdminBorbaloCarModel) => {
    setCarMake(item.make);
    setCarModel(item.model);
    modalUpload3dFiles.handleOpenModal();
  };

  const onCommonUploadPress = () => {
    setCarMake("");
    setCarModel("");
    modalUpload3dFiles.handleOpenModal();
  };

  const onViewPress = (item: AdminBorbaloCarModel) => {
    if (item.model3d) {
      load3dModel(item.model3d.name);
      setFileName(item.model3d.name);
    }
  };

  const changeFilterType = (value: boolean) => {
    setFilterType(value);
  };

  useEffect(() => {
    loadData();
  }, [filterType]);

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {carsData?.total}
      </Typography>
      <Box
        display="flex"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
        component="section"
      >
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            key="combo-box-demo2"
            id="combo-box-demo2"
            options={seletorData}
            sx={{ width: 300, backgroundColor: "#fff", marginBottom: 1.6 }}
            defaultValue={seletorData[0]}
            onChange={(_, value) => {
              if (value) {
                changeFilterType(value.value);
              }
            }}
            getOptionLabel={option => {
              return option.label;
            }}
            renderInput={params => <TextField {...params} label="" />}
          />
        </FormControl>
        <Button onClick={onCommonUploadPress}>Upload 3d model</Button>
      </Box>
      <div className="column"></div>
      <MyTable tableData={tableData} />
      <ModalUpload3dFiles
        open={modalUpload3dFiles.open}
        handleCloseModal={modalUpload3dFiles.handleCloseModal}
        initMake={carMake}
        initModel={carModel}
        refreshPage={loadData}
      />
      <ModalManage3dFile
        open={modalManage3dFiles.open}
        handleCloseModal={modalManage3dFiles.handleCloseModal}
        sourceFile={file}
        fileName={fileName}
        removeModel={() => {}}
        showRemoveButton={false}
      />
    </>
  );
};

export default BorbaloCars;
