import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Input,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";

import useAsync from "hooks/useAsync";
import {
  AwardSubscriptionRequest,
  IAwardSubscriptionRequest,
} from "services/borbalo-main.service";
import { adminProfilesService } from "services/adminProfilesService";

const defaultFormData: IAwardSubscriptionRequest = {
  userPhone: "",
  registrations: [],
  monthCount: 0,
  messageEn: "",
  messageGe: "",
};

const AddSubscriptionModal = ({
  isOpen,
  toggleModal,
  isButtonDisabled,
  handleAddClick,
}: {
  isOpen: boolean;
  toggleModal: () => void;
  isButtonDisabled?: boolean;
  handleAddClick: () => void;
}) => {
  const [formData, setFormData] =
    useState<IAwardSubscriptionRequest>(defaultFormData);

  const { isLoading, isSuccess, run } = useAsync<void, any>(true);

  useEffect(() => {
    if (isOpen) {
      setFormData(defaultFormData);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      toggleModal();
      handleAddClick();
    }
  }, [isSuccess]);

  const handleAddSubscription = () => {
    if (isOpen) {
      run(
        adminProfilesService.createAwardedSubscription(
          new AwardSubscriptionRequest(formData),
        ),
      );
    }
  };

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === "registration") {
      return setFormData(prevState => ({
        ...prevState,
        registrations: [event.target.value.trim()],
      }));
    }

    if (event.target.id === "monthCount") {
      return setFormData(prevState => ({
        ...prevState,
        monthCount: Number(event.target.value),
      }));
    }

    setFormData(prevState => ({
      ...prevState,
      [event.target.id]: event.target.value.trim(),
    }));
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box
        sx={{
          ...style,
        }}
        component="section"
      >
        <Typography variant="h4" mb={2}>
          Add new subscription
        </Typography>
        <div>
          <InputLabel htmlFor="userPhone">Phone number *</InputLabel>
          <Input id="userPhone" fullWidth onChange={onChangeForm} />
        </div>
        <div>
          <InputLabel htmlFor="registration">Registration number</InputLabel>
          <Input id="registration" fullWidth onChange={onChangeForm} />
        </div>
        <div>
          <InputLabel htmlFor="monthCount">Month count *</InputLabel>
          <Input
            id="monthCount"
            fullWidth
            type="number"
            onChange={onChangeForm}
          />
        </div>
        <div>
          <InputLabel htmlFor="messageEn">Message En *</InputLabel>
          <Input id="messageEn" fullWidth onChange={onChangeForm} />
        </div>
        <div>
          <InputLabel htmlFor="messageGe">Message Ge *</InputLabel>
          <Input id="messageGe" fullWidth onChange={onChangeForm} />
        </div>

        <Button
          sx={{
            width: "100%",
          }}
          onClick={handleAddSubscription}
          disabled={isLoading || isButtonDisabled}
        >
          Add
        </Button>
        <Button
          sx={{
            width: "100%",
          }}
          onClick={toggleModal}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: 600,
  maxHeight: window.innerHeight - 50,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 3.5,
  p: 4,
};

export default AddSubscriptionModal;
