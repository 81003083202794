import { useState, useEffect } from "react";
import { Typography, Button, Box } from "@mui/material";

import useModal from "hooks/useModal";
import {
  AdminBorbaloCarModel,
  AdminCar3dModel,
  PagedListOfAdminCar3dModel,
} from "services/borbalo-main.service";
import { adminCarModelsService } from "services/adminCarModelsService";
import MyTable from "components/MyTable";
import ModalManage3dFile from "./ModalManage3dFile";
import ModalBorbaloCarsUsedFor3dModel from "./ModalBorbaloCarsUsedFor3dModel";

import { useLoad3dModel } from "./useLoad3dModel";
import moment from "moment";

const Cars3dModels = ({ title }: { title: string }) => {
  const { file, load3dModel } = useLoad3dModel();
  const modalManage3dFiles = useModal();
  const modalBorbaloCarsUsedFor3dModel = useModal();

  const [fileName, setFileName] = useState<string | null>(null);
  const [models3dData, setModels3dData] = useState<PagedListOfAdminCar3dModel>(
    new PagedListOfAdminCar3dModel(),
  );
  const [uploadedThisMonthCount, setUploadedThisMonthCount] = useState<
    number | null
  >(null);
  const [carsData, setCarsData] = useState<AdminBorbaloCarModel[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    adminCarModelsService.listOf3dModels().then(e => {
      if (e) {
        setModels3dData(e);
        updateUploadedThisMonthCountCaption(e.entities);
      }
    });
  };

  const updateUploadedThisMonthCountCaption = (items?: AdminCar3dModel[]) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let count = items?.filter(x => x.createdAt >= startOfMonth)?.length;
    if (count) {
      setUploadedThisMonthCount(count);
    }
  };

  const dataInfo =
    models3dData?.entities?.map(item => [
      item.make,
      item.model,
      moment(item.createdAt).format("DD.MM.YYYY"),
      item.year,
      item.usedForCarsAmount,
      <>
        {item.usedForModels.length > 0 && (
          <Button onClick={() => onViewCarsPress(item.usedForModels)}>
            View cars
          </Button>
        )}
      </>,
    ]) ?? [];

  const tableData = [
    ["Make", "Model", "Added at", "Year", "Used for cars", ""],
    ...dataInfo,
  ];

  useEffect(() => {
    if (file) {
      modalManage3dFiles.handleOpenModal();
    }
  }, [file]);

  const onViewCarsPress = (items: AdminBorbaloCarModel[]) => {
    setCarsData(items);
    modalBorbaloCarsUsedFor3dModel.handleOpenModal();
  };

  const onPress = (index: number) => {
    const entity = models3dData?.entities?.[index];
    if (entity?.name) {
      load3dModel(entity.name);
      setFileName(entity.name);
    }
  };

  const remove3dModel = (fileName: string) => {
    setModels3dData(s => {
      var entities = s.entities?.filter(x => x.name != fileName);
      updateUploadedThisMonthCountCaption(entities);
      return new PagedListOfAdminCar3dModel({
        entities: entities,
        total: entities?.length,
      });
    });
  };

  const onPressDisabled = (index: number) => {
    const entity = models3dData?.entities?.[index];
    return !entity?.name;
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
      </Typography>
      <Box
        display="flex"
        sx={{ justifyContent: "flex-start", alignItems: "center" }}
        component="section"
      >
        <Typography variant="h4" mb={1.6}>
          Total {models3dData?.total}
        </Typography>
        {uploadedThisMonthCount && (
          <Typography variant="h4" mb={1.6} ml={1}>
            / this month {uploadedThisMonthCount}
          </Typography>
        )}
      </Box>

      <div className="column"></div>
      <MyTable
        tableData={tableData}
        onPress={onPress}
        buttonText={"Manage"}
        onPressDisabled={onPressDisabled}
      />
      <ModalManage3dFile
        open={modalManage3dFiles.open}
        handleCloseModal={modalManage3dFiles.handleCloseModal}
        sourceFile={file}
        fileName={fileName}
        removeModel={remove3dModel}
        showRemoveButton={true}
      />
      <ModalBorbaloCarsUsedFor3dModel
        open={modalBorbaloCarsUsedFor3dModel.open}
        handleCloseModal={modalBorbaloCarsUsedFor3dModel.handleCloseModal}
        cars={carsData}
      />
    </>
  );
};

export default Cars3dModels;
