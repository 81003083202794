import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useAsync from "hooks/useAsync";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { adminFinesService } from "services/fines";
import {
  PagedListOfFineCamera,
  RemovedLocation,
  PagedListOfRemovedLocation,
  RecoverFineLocationRequest,
} from "services/borbalo-main.service";
import { IconChevronDown } from "pages/Fines/assets/IconChevron";
import clsx from "clsx";
import uniqueBy from "lodash.uniqby";
import { useSearch } from "components/SearchComponent/useSearch";
import Viewer from "pages/Fines/Viewer";
import RestoreIcon from "@mui/icons-material/Restore";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  text: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
  },
  tableCell: {
    maxWidth: 200,
    borderStyle: "border-box",
  },
  withoutBorder: {
    borderBottomWidth: "0 !important",
  },
  group: { top: -50, position: "relative" },
});

const header: {
  title: string;
  field?: keyof RemovedLocation | undefined;
}[] = [
  { title: "Group Id" },
  { title: "Number of fines", field: "finesCount" },
  { title: "Pictures" },
  { title: "Region", field: "region" },
  { title: "Municipality", field: "municipality" },
  { title: "Location From Image en", field: "locationFromImageLatin" },
  { title: "Location From Image ge", field: "locationFromImageTranscription" },
  { title: "Removed By", field: "removedByAdmin" },
];

const PAGINATION_LIMIT = 500;

const RemovedFines = ({ title }: { title: string }) => {
  // IMAGES
  const [isViewerOpen, setIsViewerOpen] = useState(-1);
  const openImageViewer = useCallback((index: number) => {
    setIsViewerOpen(index);
  }, []);
  const closeImageViewer = () => {
    setIsViewerOpen(-1);
  };

  const lastRowRef = useRef(null);
  const getFinesAsync = useAsync<PagedListOfRemovedLocation, any>();
  const classes = useStyles();
  const recoverLocationAsync = useAsync<PagedListOfFineCamera, any>();
  const [finesData, setFinesData] = useState<RemovedLocation[]>([]);
  const [count, setCount] = useState(PAGINATION_LIMIT);

  const { search, SearchComponent } = useSearch();

  useEffect(() => {
    getFinesAsync.run(adminFinesService.getRemovedLocations());
  }, []);

  useEffect(() => {
    const data = getFinesAsync.data?.entities;
    if (data) {
      setFinesData(data);
    }
  }, [getFinesAsync.data]);

  const restoreLocation = (item: RemovedLocation) => {
    recoverLocationAsync
      .run(
        adminFinesService.recoverLocation(
          new RecoverFineLocationRequest({
            region: item.region,
            municipality: item.municipality,
            locationFromImageLatin: item.locationFromImageLatin,
          }),
        ),
      )
      .then(e => {
        if (!e) {
          setFinesData(s =>
            s.filter(
              i =>
                !(
                  i.locationFromImageLatin === item.locationFromImageLatin &&
                  i.region === item.region &&
                  i.municipality === item.municipality
                ),
            ),
          );
        }
      });
  };

  const [activeKey, setActiveKey] = useState("");

  const [sortData, setSortData] = useState<{
    sortIndex: number;
    sortType: string;
    sortField: keyof RemovedLocation;
  }>({
    sortIndex: 1,
    sortType: "desc",
    sortField: "finesCount",
  });

  const onHeaderClick = (
    index: number,
    sortField: keyof RemovedLocation | undefined,
  ) => {
    if (!sortField) {
      return;
    }

    setSortData(prevState => {
      if (prevState.sortIndex === index) {
        return {
          sortIndex: index,
          sortType: prevState.sortType === "asc" ? "desc" : "asc",
          sortField,
        };
      }

      return {
        sortIndex: index,
        sortType: "desc",
        sortField,
      };
    });
  };

  const sortedFinesData1 = useMemo(() => {
    if (sortData.sortField !== "finesCount" || search) {
      return uniqueBy(
        finesData,
        (item: RemovedLocation) => item.locationFromImageLatin,
      )
        .filter(
          item =>
            item.locationFromImageLatin
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item.locationFromImageTranscription
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item.region?.toLowerCase().includes(search.toLowerCase()) ||
            item.municipality?.toLowerCase().includes(search.toLowerCase()),
        )
        .map(
          item =>
            new RemovedLocation({
              ...item,
              finesCount: finesData.reduce((acc, val) => {
                if (
                  val.locationFromImageLatin === item.locationFromImageLatin &&
                  val.region === item.region &&
                  val.municipality === item.municipality
                ) {
                  return acc + val.finesCount;
                }
                return acc;
              }, 0),
            }),
        )
        .sort((a, b) => {
          const sortCondition =
            sortData.sortType === "desc"
              ? a[sortData.sortField]! < b[sortData.sortField]!
              : a[sortData.sortField]! > b[sortData.sortField]!;
          return sortCondition ? 1 : -1;
        });
    } else {
      return finesData
        .slice()
        .sort((a, b) => {
          const condition = a.positionType! < b.positionType!;
          return condition ? 1 : -1;
        })
        .sort((a, b) => {
          const sortCondition =
            sortData.sortType === "desc"
              ? a.cameraGroupId! < b.cameraGroupId!
              : a.cameraGroupId! > b.cameraGroupId!;
          return sortCondition ? 1 : -1;
        })
        .sort((a, b) => {
          const sortCondition =
            sortData.sortType === "desc"
              ? b.finesCount - a.finesCount
              : a.finesCount - b.finesCount;

          return sortCondition;
        });
    }
  }, [finesData, sortData, search]);

  const sortedFinesData = useMemo(() => {
    return sortedFinesData1.filter((_, index) => index < count);
  }, [sortedFinesData1, count]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setCount(s => s + PAGINATION_LIMIT);
      }
    });
    if (lastRowRef.current) {
      observer.observe(lastRowRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [finesData.length, count, sortData]);

  useEffect(() => {
    setCount(PAGINATION_LIMIT);
  }, [sortData]);

  const grouppedInfo = useMemo(() => {
    const ids = sortedFinesData.map(({ cameraGroupId }) => cameraGroupId);

    const filteredIds = ids.filter((str, index, arr) => {
      const count = arr.reduce((acc, val) => (val === str ? acc + 1 : acc), 0);

      return count === 2;
    });

    const filteredIndexes = filteredIds.map(id =>
      sortedFinesData.findIndex(({ cameraGroupId }) => cameraGroupId === id),
    );
    const s = [...new Set(filteredIndexes)];

    return { filteredIds, filteredIndexes: s };
  }, [sortedFinesData]);

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        component="section"
      >
        <Typography variant="h3" mb={1.6}>
          {title}
          {getFinesAsync.isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        component="section"
      >
        <Typography variant="h4" mb={1.6}>
          Total {sortedFinesData1.length}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
        component="section"
      >
        {SearchComponent}
      </Box>
      <TableContainer
        component={Paper}
        style={{ marginBottom: 400, overflow: "visible" }}
      >
        <Table className={classes.table} size="small" aria-label="my table">
          <TableHead>
            <TableRow>
              {header.map((headerItem, index) => (
                <TableCell
                  onClick={() => onHeaderClick(index, headerItem.field)}
                  key={headerItem.title}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer",
                      color: !headerItem.field ? "grey" : undefined,
                    }}
                    component="section"
                  >
                    {headerItem.title}

                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        marginLeft: 0.8,
                        alignItems: "center",
                      }}
                      component="section"
                    >
                      {sortData.sortIndex === index && (
                        <IconChevronDown
                          size={16}
                          className={
                            (clsx("color-grey"),
                            sortData.sortType === "asc" ? "flip" : undefined)
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
              ))}

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFinesData.map((item, index) => (
              <React.Fragment
                key={
                  item.cameraGroupId +
                  item.locationFromImageLatin +
                  item.cameraGroupId +
                  item.locationFromImageTranscription +
                  index
                }
              >
                <div
                  ref={
                    !getFinesAsync.isLoading &&
                    sortedFinesData.length <
                      Number(sortedFinesData1.length ?? 0) &&
                    index === sortedFinesData.length - 1
                      ? lastRowRef
                      : undefined
                  }
                ></div>
                <TableRow
                  onClick={() => setActiveKey(item.locationFromImageLatin)}
                  style={{
                    display: "table-row",
                    backgroundColor:
                      item.locationFromImageLatin === activeKey
                        ? "rgba(0, 198, 190, 0.1)"
                        : "white",
                  }}
                >
                  {sortData.sortField !== "finesCount" ||
                  grouppedInfo.filteredIndexes.includes(index) ? (
                    <TableCell
                      className={
                        sortData.sortField === "finesCount"
                          ? classes.withoutBorder
                          : undefined
                      }
                      component="th"
                      scope="row"
                    >
                      {sortData.sortField === "finesCount"
                        ? ""
                        : item.cameraGroupId.slice(0, 8)}
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row">
                      <div
                        className={
                          grouppedInfo.filteredIds.includes(item.cameraGroupId)
                            ? classes.group
                            : undefined
                        }
                      >
                        {item.cameraGroupId.slice(0, 8)}
                      </div>
                    </TableCell>
                  )}
                  {sortData.sortField !== "finesCount" ||
                  grouppedInfo.filteredIndexes.includes(index) ? (
                    <TableCell
                      className={
                        sortData.sortField === "finesCount"
                          ? classes.withoutBorder
                          : undefined
                      }
                      component="th"
                      scope="row"
                    >
                      {sortData.sortField === "finesCount"
                        ? ""
                        : item.finesCount}
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row">
                      <div
                        className={
                          grouppedInfo.filteredIds.includes(item.cameraGroupId)
                            ? classes.group
                            : undefined
                        }
                      >
                        {item.finesCount}
                      </div>
                    </TableCell>
                  )}
                  <TableCell component="th" scope="row">
                    <Button onClick={() => openImageViewer(index)}>
                      Show media
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.region}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.municipality}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.locationFromImageLatin}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.locationFromImageTranscription}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.removedByAdmin ? "Admin" : "Automaticaly"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Button onClick={() => restoreLocation(item)}>
                      <RestoreIcon />
                    </Button>
                  </TableCell>
                  {index === isViewerOpen && (
                    <Viewer
                      images={item.images}
                      closeImageViewer={closeImageViewer}
                    />
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {(!getFinesAsync.data || count < (sortedFinesData1?.length ?? 0)) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginTop: 2,
                marginBottom: 2,
              }}
            />
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default React.memo(RemovedFines);
