import { Box, Modal, Button } from "@mui/material";
import MyTable from "components/MyTable";
import modalStyles from "components/ModalComponent/styles";

import { AdminBorbaloCarModel } from "services/borbalo-main.service";
import moment from "moment";

const ModalBorbaloCarsUsedFor3dModel = ({
  open,
  handleCloseModal,
  cars,
}: {
  open: boolean;
  handleCloseModal: () => void;
  cars: AdminBorbaloCarModel[] | null;
}) => {
  const dataInfo =
    cars?.map(item => [
      item.make,
      item.model,
      moment(item.createdAt).format("DD.MM.YYYY"),
      item.bodyTypes.join(", "),
      item.yearFrom,
      item.yearTo,
      item.count,
    ]) ?? [];

  const closeModal = () => {
    handleCloseModal();
  };
  const tableData = [
    [
      "Make",
      "Model",
      "Added at",
      "Body types",
      "Year from",
      "Year to",
      "Count",
    ],
    ...dataInfo,
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="model-modal-title"
        aria-describedby="model-modal-description"
        disableScrollLock
      >
        <Box
          sx={{
            ...modalStyles,
          }}
          component="section"
        >
          <MyTable tableData={tableData} />
          <Button sx={{ width: "100%" }} onClick={closeModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalBorbaloCarsUsedFor3dModel;
