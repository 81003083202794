import { useState } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<[] | {} | null>(null);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return {
    open,
    handleOpenModal,
    handleCloseModal,
    modalData,
    setModalData,
  };
};

export default useModal;
