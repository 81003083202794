const modalStyles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    minHeight: 700,
    minWidth: 760,
    transform: "translate(-50%, -50%)",
    bgcolor: "#F1F4F5",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: window.innerHeight - 50,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    p: 4,
  };
  
  export default modalStyles;
  