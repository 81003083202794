import { useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Object3DEventMap, Group, Mesh, Object3D } from "three";

const Box = ({
  model,
  isLeftButtonPressed,
  isRightButtonPressed,
}: {
  model: Group<Object3DEventMap>;
  isLeftButtonPressed: boolean;
  isRightButtonPressed: boolean;
}) => {
  const ref = useRef<Mesh>(null);
  const refObject = useRef<Object3D>(null);
  const [obj, setObj] = useState<Group<Object3DEventMap> | null>(null);

  useFrame((state, delta) => {
    if (isLeftButtonPressed || isRightButtonPressed) {
      return;
    }

    if (ref.current && refObject.current) {
      ref.current.rotation.y -= delta / 3;
    }
  });

  useEffect(() => {
    if (model) {
      setObj(model);
    }
  }, [model]);

  return (
    <mesh ref={ref} receiveShadow>
      <meshStandardMaterial />
      <shadowMaterial attach="material" opacity={1} color="black" />

      {obj && (
        <primitive
          ref={refObject}
          object={obj}
          scale={1}
          position={[0, -1, 0]}
        />
      )}
    </mesh>
  );
};

const FbxModel = ({ model }: { model: Group<Object3DEventMap> }) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const [isLeftButtonPressed, setIsLeftButtonPressed] = useState(false);
  const [isRightButtonPressed, setIsRightButtonPressed] = useState(false);

  const handleMouseDown = (event: any) => {
    if (event.button === 0) {
      setIsLeftButtonPressed(true);
    }
    if (event.button === 2) {
      setIsRightButtonPressed(true);
    }
  };

  const handleMouseUp = (event: any) => {
    if (event.button === 0) {
      setIsLeftButtonPressed(false);
    }
    if (event.button === 2) {
      setIsRightButtonPressed(false);
    }
  };

  return (
    <div
      style={{
        height: "458px",
        alignContent: "end",
      }}
    >
      {model && (
        <Canvas
          ref={ref}
          camera={{
            position: [0, 0, 17],
            fov: 15,
          }}
          shadows
          style={{
            height: "458px",
            width: "100%",
            marginBottom: 32,
          }}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMouseDown}
        >
          <directionalLight position={[0, 2, -5]} castShadow intensity={6} />
          <ambientLight intensity={1.5} />
          <pointLight position={[4, 0, 4]} castShadow />

          <Box
            model={model}
            isLeftButtonPressed={isLeftButtonPressed}
            isRightButtonPressed={isRightButtonPressed}
          />

          <OrbitControls />
          <ambientLight intensity={1} />
        </Canvas>
      )}
    </div>
  );
};

export default FbxModel;
