import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect, useMemo, useState } from "react";
import {
  AddFineCameraRequest,
  FineCamera,
  PagedListOfAdminGroupedRadarReports,
  AdminGroupedRadarReports,
  RadarReportType,
} from "services/borbalo-main.service";
import moment from "moment";
import { adminNavigationService } from "services/adminNavigationService";

import {
  AccidentIconSvg,
  CameraIconSvg,
  HazardIconSvg,
  PoliceIconSvg,
} from "./assets/report-icons";
import AllReportsOnMap from "components/AllReportsOnMap";
import AddNewLocationModal from "components/AddNewLocationModal";

type FilterType = {
  reportType: RadarReportType | null;
  isActive: boolean;
  isExpired: boolean;
};

const seletorData = [
  {
    value: {
      reportType: null,
      isActive: true,
      isExpired: false,
    },
    label: "All Active Reports",
  },
  {
    value: {
      reportType: RadarReportType.Camera,
      isActive: true,
      isExpired: false,
    },
    label: "Active Cameras",
  },
  {
    value: {
      reportType: RadarReportType.Camera,
      isActive: true,
      isExpired: true,
    },
    label: "All Cameras",
  },
  {
    value: {
      reportType: RadarReportType.Accident,
      isActive: true,
      isExpired: false,
    },
    label: "Active Accidents",
  },
  {
    value: {
      reportType: RadarReportType.Hazard,
      isActive: true,
      isExpired: false,
    },
    label: "Active Hazards",
  },
  {
    value: {
      reportType: RadarReportType.Police,
      isActive: true,
      isExpired: false,
    },
    label: "Active Polices",
  },
];

const seletorExpiredData = [
  {
    value: {
      reportType: null,
      isActive: false,
      isExpired: true,
    },
    label: "All Expired Reports",
  },
  {
    value: {
      reportType: RadarReportType.Camera,
      isActive: false,
      isExpired: true,
    },
    label: "Expired Cameras",
  },
  {
    value: {
      reportType: RadarReportType.Accident,
      isActive: false,
      isExpired: true,
    },
    label: "Expired Accidents",
  },
  {
    value: {
      reportType: RadarReportType.Hazard,
      isActive: false,
      isExpired: true,
    },
    label: "Expired Hazards",
  },
  {
    value: {
      reportType: RadarReportType.Police,
      isActive: false,
      isExpired: true,
    },
    label: "Expired Polices",
  },
];

export const icons = {
  [RadarReportType.Accident]: <AccidentIconSvg size={24} />,
  [RadarReportType.Hazard]: <HazardIconSvg size={24} />,
  [RadarReportType.Police]: <PoliceIconSvg size={24} />,
  [RadarReportType.Camera]: <CameraIconSvg size={24} />,
};

const defaultPerPage = 500;

const RadarReports = ({
  title,
  expired,
}: {
  title: string;
  expired?: boolean;
}) => {
  const { data, isLoading, run } = useAsync<
    PagedListOfAdminGroupedRadarReports,
    any
  >();
  const [addCameraModalOpen, setAddCameraModalOpen] = useState<
    AdminGroupedRadarReports | undefined
  >(undefined);
  const deleteReportAsync = useAsync<void, any>();
  const makeCameraPermamentAsync = useAsync<void, any>();
  const [page, setPage] = useState(1);
  const [isMapOpen, setIsMapOpen] = useState<
    AdminGroupedRadarReports | boolean
  >(false);
  const [filterType, setFilterType] = useState<FilterType>(
    expired ? seletorExpiredData[0].value : seletorData[0].value,
  );

  useEffect(() => {
    const value = (expired ? seletorExpiredData : seletorData)[0].value;
    setFilterType(value);
  }, [expired]);

  const onDeleteClick = (reportId: string) => {
    deleteReportAsync
      .run(adminNavigationService.removeRadarReport(reportId))
      .then(() => {
        run(
          adminNavigationService.getAllRadarReports(
            filterType.reportType,
            filterType.isActive,
            filterType.isExpired,
            page,
            defaultPerPage,
          ),
        );
      });
  };

  useEffect(() => setPage(1), [filterType]);

  useEffect(() => {
    run(
      adminNavigationService.getAllRadarReports(
        filterType.reportType,
        filterType.isActive,
        filterType.isExpired,
        page,
        defaultPerPage,
      ),
    );
  }, [filterType, page]);

  const sortedData = useMemo(() => {
    if (data?.entities) {
      return data.entities.slice().sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
      });
    }

    return [];
  }, [data?.entities]);

  const dataInfo =
    sortedData?.map(item => [
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 1.6,
          gap: 8,
        }}
      >
        {icons[item.type]} {item.type}
      </div>,
      moment(item.createdAt).format("DD.MM.YYYY HH:mm"),
      item.location.replace(",", ", "),
      <>
        {item.type === RadarReportType.Camera ? (
          <Button onClick={() => setAddCameraModalOpen(item)}>
            Make Camera Permanent
          </Button>
        ) : (
          <></>
        )}
      </>,
      <Button onClick={() => setIsMapOpen(item)}>Show on map</Button>,
      <Button onClick={() => onDeleteClick(item.id)}>
        <DeleteIcon />
      </Button>,
    ]) ?? [];

  const tableData = [
    ["Report Type", "Report Date", "Location", "", "", ""],
    ...dataInfo,
  ];

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>

      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        component="section"
      >
        {expired ? (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              key="combo-box-demo1"
              id="combo-box-demo1"
              options={seletorExpiredData}
              sx={{ width: 300, backgroundColor: "#fff", marginBottom: 1.6 }}
              defaultValue={seletorExpiredData[0]}
              onChange={(_, value) => {
                if (value) {
                  setFilterType(value?.value);
                }
              }}
              getOptionLabel={option => {
                return option.label;
              }}
              renderInput={params => <TextField {...params} label="" />}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              key="combo-box-demo2"
              id="combo-box-demo2"
              options={seletorData}
              sx={{ width: 300, backgroundColor: "#fff", marginBottom: 1.6 }}
              defaultValue={seletorData[0]}
              onChange={(_, value) => {
                if (value) {
                  setFilterType(value?.value);
                }
              }}
              getOptionLabel={option => {
                return option.label;
              }}
              renderInput={params => <TextField {...params} label="" />}
            />
          </FormControl>
        )}
        <Button
          style={{
            width: 220,
          }}
          onClick={() => setIsMapOpen(true)}
        >
          Show Reports on map
        </Button>
      </Box>
      <MyTable tableData={tableData} />
      {!!data?.total && data?.total >= defaultPerPage && (
        <Pagination
          page={page}
          count={Math.ceil(data?.total / defaultPerPage)}
          sx={{
            marginTop: 2,
            alignSelf: "flex-end",
          }}
          onChange={(_, page) => {
            setPage(page);
          }}
        />
      )}
      <AllReportsOnMap
        open={isMapOpen}
        handleCloseModal={() => setIsMapOpen(false)}
        allReports={data?.entities || []}
      />
      <AddNewLocationModal
        open={!!addCameraModalOpen}
        handleClose={() => setAddCameraModalOpen(undefined)}
        coordinates={addCameraModalOpen?.location}
        onSave={(newCamera: FineCamera) => {
          if (addCameraModalOpen) {
            makeCameraPermamentAsync
              .run(
                adminNavigationService.makeUserCameraPermanent(
                  addCameraModalOpen.id,
                  new AddFineCameraRequest({
                    ...newCamera,
                  }),
                ),
              )
              .then(() => {
                setAddCameraModalOpen(undefined);
                run(
                  adminNavigationService.getAllRadarReports(
                    filterType.reportType,
                    filterType.isActive,
                    filterType.isExpired,
                    page,
                    defaultPerPage,
                  ),
                );
              });
          }
        }}
      />
    </>
  );
};

export default RadarReports;
