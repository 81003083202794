import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "pages/Login";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "store";
import "css/index.scss";
import Home from "pages/Home";
import {
  isUserSignedInSelector,
  userRolesSelector,
} from "store/auth/selectors";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { logout, setIsUserSingedIn, setRoles } from "store/auth/slice";
import { getTokensFromStorage } from "utils/tokens";
import BlockedCars from "pages/BlockedCars";
import InactiveCars from "pages/InactiveCars";
import { InactiveCarsPage } from "services/borbalo-main.service";
import RegisterCardIssues from "pages/RegisterCardIssues";
import SupportCallRequests from "pages/SupportCallRequests";
import Fines from "pages/Fines";
import FinesLocations from "pages/FinesLocations";
import { ToastContainer } from "react-toastify";
import { ActionIcon } from "components/ActionIcon";
import { IconX } from "icons/IconX";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RenamedLocations from "pages/RenamedLocations";
import UsersFeedbacks from "pages/UsersFeedbacks";
import Articles from "pages/Articles";
import RemovedFines from "pages/RemovedFines";
import RadarReports from "pages/RadarReports";
import AdsProviders from "pages/AdsProviders";
import Ads from "pages/Ads";
import ProvidersBrands from "pages/ProvidersBrands";
import DiscountProgramProviders from "pages/DiscountProgramProviders";
import ProvidersBrandsLocations from "pages/ProvidersBrandsLocations";
import BusinessInvoicesProviders from "pages/BusinessInvoicesProviders";
import ProvidersInvoices from "pages/ProvidersInvoices";
import BusinessInvoicesByMonthProviders from "pages/BusinessInvoicesByMonthProviders";
import AwardedMonthsProviders from "pages/AwardedMonthsProviders";
import DiscountProgramLocations from "pages/DiscountProgramLocations";
import Cars3dModels from "pages/Cars3dModels";
import BorbaloCars from "pages/BorbaloCars";
import { IS_DEV } from "services/apiUrl";

const theme = createTheme({
  palette: {
    primary: {
      light: "#00c6be",
      main: "#00c6be",
      dark: "#00c6be",
    },
  },
});

const navBar = [
  {
    path: "/support-call-requests",
    label: "Support call requests",
    Element: <SupportCallRequests />,
    roles: ["borbalo-admin"],
  },
  {
    path: "/blocked-cars",
    label: "Blocked cars",
    Element: <BlockedCars />,
    roles: ["borbalo-admin"],
  },
  {
    path: "/inactive-cars-with-cards",
    label: "Inactive cars w/ cards",
    Element: (
      <InactiveCars
        page={InactiveCarsPage.WithCards}
        title={"Inactive cars with payment cards"}
      />
    ),
    role: ["borbalo-admin"],
  },
  {
    path: "/inactive-cars-without-cards",
    label: "Inactive cars w/o cards",
    Element: (
      <InactiveCars
        page={InactiveCarsPage.WithoutCards}
        title={"Inactive cars without payment cards"}
      />
    ),
    roles: ["borbalo-admin"],
  },
  {
    path: "/contacted-inactive-cars-with-cards",
    label: "Inactive cars w/ cards (contacted)",
    Element: (
      <InactiveCars
        page={InactiveCarsPage.WithCardsContacted}
        title={"Inactive cars with payment cards previously contacted"}
      />
    ),
    roles: ["borbalo-admin"],
  },
  {
    path: "/register-card-issues",
    label: "Register card issues",
    Element: <RegisterCardIssues />,
    roles: ["borbalo-admin"],
  },
  {
    path: "/fines",
    label: "Fines locations",
    Element: <Fines />,
    roles: ["MapStaff"],
  },
  {
    path: "/removed-fines",
    label: "Removed Fines locations",
    Element: <RemovedFines title="Removed Fines locations" />,
    roles: ["MapStaff"],
  },
  {
    path: "/renamed-locations",
    label: "Renamed Locations",
    Element: <RenamedLocations title="Renamed Locations" />,
    roles: ["MapStaff"],
  },
  {
    path: "/users-feedbacks",
    label: "Users Feedbacks",
    Element: <UsersFeedbacks title="Users Feedbacks" />,
    roles: ["RadarFeedbackSupport"],
  },
  {
    path: "/articles",
    label: "Articles",
    Element: <Articles title="Articles" />,
    roles: ["ArticleEditor"],
  },
  {
    path: "/radar-reports",
    label: "Radar Reports",
    Element: <RadarReports title="Radar Reports" />,
    roles: ["MapStaff"],
  },
  {
    path: "/expired-radar-reports",
    label: "Expired Radar Reports",
    Element: <RadarReports title="Expired Radar Reports" expired={true} />,
    roles: ["MapStaff"],
  },
  {
    path: "/ads-providers",
    label: "Ads Providers",
    Element: <AdsProviders title="Ads Providers" />,
    roles: ["AdsAdmin"],
  },
  {
    path: "/discount-program-providers",
    label: "Discount Program Providers",
    Element: <DiscountProgramProviders title="Discount Program Providers" />,
    roles: ["DiscountProgramAdmin"],
  },
  {
    path: "/discount-program-locations",
    label: "Discount Program Locations",
    Element: <DiscountProgramLocations title="Discount Program Locations" />,
    roles: ["DiscountProgramAdmin"],
  },
  {
    path: "/business-invoices",
    label: "Business invoices by сompanies",
    Element: (
      <BusinessInvoicesProviders title="Business invoices by сompanies" />
    ),
    roles: ["Accountant"],
  },
  {
    path: "/business-invoices-by-month",
    label: "Business invoices by month",
    Element: (
      <BusinessInvoicesByMonthProviders title="Business invoices by month" />
    ),
    roles: ["Accountant"],
  },
  {
    path: "/awarded-months",
    label: "Awarded months",
    Element: <AwardedMonthsProviders title="Awarded months" />,
    roles: ["borbalo-admin"],
  },
  {
    path: "/borbalo-cars",
    label: "Borbalo cars",
    Element: <BorbaloCars title="Borbalo cars" />,
    roles: ["Car3dModelEditor"],
  },
  {
    path: "/cars-3d-models",
    label: "Cars 3D models",
    Element: <Cars3dModels title="Cars 3D models" />,
    roles: ["Car3dModelEditor"],
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: 340,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 340,
    marginTop: IS_DEV ? 20 : 0,
  },
  content: {
    flexGrow: 1,
    paddingTop: 100,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const isUserSignedIn = useSelector(isUserSignedInSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const userRoles = useSelector(userRolesSelector);

  useEffect(() => {
    if (!value && navBar.some(item => item.path === location.pathname)) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkAuth = async () => {
      const tokens = await getTokensFromStorage();
      if (isUserSignedIn === undefined) {
        dispatch(setRoles(tokens?.roles ?? []));
        dispatch(setIsUserSingedIn(Boolean(tokens)));
      }
    };
    checkAuth();
  }, []);
  const [value, setValue] = useState<string | undefined>();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (location.pathname) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  if (isUserSignedIn === undefined) {
    return null;
  }

  if (!isUserSignedIn) {
    navigate("/login");
  }

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={{
            zIndex: 1300,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Button
              color="inherit"
              onClick={() => navigate("/")}
              sx={{ flexGrow: 1 }}
            >
              {userRoles.includes("Accountant")
                ? "Borbalo Accountant's Panel"
                : "Borbalo Admin Panel"}
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                localStorage.clear();
                dispatch(logout());
              }}
            >
              logout
            </Button>
          </Toolbar>
          {IS_DEV && <div className="dev-row">DEV Environment</div>}
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            orientation="vertical"
          >
            {navBar
              .filter(
                ({ roles }) => roles?.some(role => userRoles.includes(role)),
              )
              .map((item, index) => (
                <Tab
                  key={item.path}
                  value={item.path}
                  label={item.label}
                  onClick={() => navigate(item.path)}
                  {...a11yProps(index)}
                  sx={{
                    alignItems: "flex-start",
                    width: 340,
                  }}
                />
              ))}
          </Tabs>
        </Drawer>
        <main
          className={classes.content}
          style={{
            marginLeft: open ? 0 : -340,
          }}
        >
          <Outlet />
        </main>
      </div>
    </>
  );
};

const AuthProtectedRoute = () => {
  const dispatch = useDispatch();
  const isUserSignedIn = useSelector(isUserSignedInSelector);
  const navigate = useNavigate();
  const userRoles = useSelector(userRolesSelector);

  useEffect(() => {
    const checkAuth = async () => {
      const tokens = await getTokensFromStorage();
      if (isUserSignedIn === undefined) {
        dispatch(setRoles(tokens?.roles ?? []));
        dispatch(setIsUserSingedIn(Boolean(tokens)));
      }
    };
    checkAuth();
  }, []);

  if (isUserSignedIn === undefined) {
    return null;
  }

  if (isUserSignedIn) {
    if (userRoles.includes("borbalo-admin")) {
      navigate("/support-call-requests");
    } else if (userRoles.includes("Accountant")) {
      navigate("/business-invoices");
    } else if (userRoles.includes("ArticleEditor")) {
      navigate("/articles");
    } else if (userRoles.includes("MapStaff")) {
      navigate("/fines");
    } else if (userRoles.includes("RadarFeedbackSupport")) {
      navigate("/users-feedbacks");
    } else if (userRoles.includes("AdsAdmin")) {
      navigate("/ads-providers");
    } else if (userRoles.includes("DiscountProgramAdmin")) {
      navigate("/discount-program-providers");
    } else if (userRoles.includes("Car3dModelEditor")) {
      navigate("/cars-3d-models");
    } else {
      navigate("/support-call-requests");
    }
  }

  return <Outlet />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ToastContainer
          position="bottom-right"
          theme="colored"
          closeButton={
            <ActionIcon className="shrink0 self-start p6">
              <IconX size={20} />
            </ActionIcon>
          }
        />
        <BrowserRouter>
          <Routes>
            <Route element={<AuthProtectedRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Home />} />
              {navBar.map(item => (
                <Route
                  key={item.path}
                  path={item.path}
                  element={item.Element ?? <Home />}
                />
              ))}
              <Route path={"/fines-locations"} element={<FinesLocations />} />

              <Route
                path={"/ads-providers/:id"}
                element={<Ads title="Ads" />}
              />
              <Route
                path={"/business-invoices/:id"}
                element={<ProvidersInvoices title="Invoices" />}
              />
              <Route
                path={"/discount-program-providers/:id"}
                element={<ProvidersBrands title="Providers Brands" />}
              />
              <Route
                path={"/discount-program-providers/:id/:brandId/locations"}
                element={<ProvidersBrandsLocations />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
