import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import LargeBannerPreview from "pages/Ads/LargeBannerPreview";
import MediumBannerPreview from "pages/Ads/MediumBannerPreview";
import SmallBannerPreview from "pages/Ads/SmallBannerPreview";
import { useEffect, useState } from "react";
import { getAccessToken } from "services";
import { API_URL } from "services/apiUrl";
import { Ad, AdBannerType } from "services/borbalo-main.service";

const PreviewModal = ({
  isOpen,
  toggleModal,
}: {
  isOpen?: Ad | undefined;
  toggleModal: () => void;
}) => {
  const [imgs, setImgs] = useState<{ [id: string]: string }[]>([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      if (isOpen) {
        const accessToken = await getAccessToken();
        setToken(accessToken || "");
      }
    };

    getToken();
  }, [isOpen]);

  useEffect(() => {}, []);

  useEffect(() => {
    const getImages = async () => {
      if (token && isOpen) {
        setLoading(true);

        try {
          const requestedImages = await Promise.all(
            (isOpen?.banners ?? []).map(async item => {
              try {
                const response = await fetch(
                  `${API_URL}${item.imageDownloadLink}`,
                  {
                    method: "GET",
                    headers: { Authorization: token },
                    cache: "default",
                  },
                );
                const blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);
                return {
                  [item.id]: objectURL,
                };
              } catch (e) {
                return { [item.id]: "" };
              }
            }),
          );
          setImgs(requestedImages);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    };

    getImages();
  }, [token, isOpen]);

  return (
    <Modal
      open={!!isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box
        sx={{
          ...style,
        }}
        component="section"
      >
        {loading && (
          <Box
            className="flex1"
            sx={{ display: "flex", justifyContent: "center" }}
            component="section"
          >
            <CircularProgress
              sx={{
                alignSelf: "center",
              }}
            />
          </Box>
        )}
        {isOpen && !loading && (
          <>
            <Typography variant="h6">Large Banner </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "row", gap: 3.2, mb: 3.2 }}
              component="section"
            >
              <LargeBannerPreview
                data={
                  isOpen.banners.find(
                    banner => banner.type === AdBannerType.Big,
                  )!
                }
                images={imgs}
                isGe={false}
              />
              <LargeBannerPreview
                data={
                  isOpen.banners.find(
                    banner => banner.type === AdBannerType.Big,
                  )!
                }
                images={imgs}
                isGe={true}
              />
            </Box>
            <Typography variant="h6">Medium Banner </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "row", gap: 3.2, mb: 3.2 }}
              component="section"
            >
              <MediumBannerPreview
                data={
                  isOpen.banners.find(
                    banner => banner.type === AdBannerType.Medium,
                  )!
                }
                images={imgs}
                isGe={false}
              />
              <MediumBannerPreview
                data={
                  isOpen.banners.find(
                    banner => banner.type === AdBannerType.Medium,
                  )!
                }
                images={imgs}
                isGe={true}
              />
            </Box>
            <Typography variant="h6">Small Banner </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "row", gap: 3.2, mb: 3.2 }}
              component="section"
            >
              <SmallBannerPreview
                data={
                  isOpen.banners.find(
                    banner => banner.type === AdBannerType.Small,
                  )!
                }
                images={imgs}
                isGe={true}
              />
            </Box>
          </>
        )}
        <Button
          sx={{
            width: "100%",
          }}
          onClick={toggleModal}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  minHeight: 700,
  minWidth: 760,
  transform: "translate(-50%, -50%)",
  bgcolor: "#F1F4F5",
  border: "2px solid #000",
  boxShadow: 24,
  maxHeight: window.innerHeight - 50,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  p: 4,
};

export default PreviewModal;
