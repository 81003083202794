import { Box, Button, Modal } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import useAsync from "hooks/useAsync";
import { memo, useEffect, useState } from "react";
import { adminFinesService } from "services/fines";
import {
  AddFineCameraRequest,
  FineCamera,
  FullFineCameraGroup,
} from "services/borbalo-main.service";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { locationTypes } from "pages/FinesLocations";
// @ts-ignore
import Streetview from "react-google-streetview";
import ImageVideoCarousel from "components/AddNewLocationModal/ImageVideoCarousel";
import {
  MapPolygons,
  MapRoutePoints,
  convertStringToCoordinates,
} from "components/AllCamerasOnMap/utils";
import InputsSection from "components/AddNewLocationModal/InputsSection";
import ChangeRouteSection from "components/AddNewLocationModal/ChangeRouteSection";

const MapComponent = ({
  address,
  setMarkerPosition,
  setRegion,
}: {
  address: string;
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<
      | {
          lat: number;
          lng: number;
        }
      | undefined
    >
  >;
  setRegion: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
}) => {
  const library = useMapsLibrary("geocoding");

  useEffect(() => {
    const decodeAddress = async () => {
      if (library) {
        try {
          const instance = new library.Geocoder();
          const result = await instance.geocode({
            address,
          });

          const lat = result?.results?.[0]?.geometry.location.lat();
          const lng = result?.results?.[0]?.geometry.location.lng();
          if (lat && lng) {
            setMarkerPosition({ lat, lng });
            setRegion({ lat, lng });
          } else {
            setMarkerPosition(undefined);
          }
        } catch (e) {}
      }
    };

    decodeAddress();
  }, [library]);

  return null;
};

const AddNewLocationModal = ({
  open,
  address,
  images,
  coordinates,
  itemRegion,
  itemMunicipality,
  locationData,
  recognizedMaxSpeed,
  activeGroup,
  handleClose,
  onSave,
  changeRoute,
}: {
  open: boolean;
  address?: string;
  images?: string[];
  itemRegion?: string;
  coordinates?: string;
  itemMunicipality?: string;
  recognizedMaxSpeed?: number;
  locationData?: FineCamera;
  handleClose: () => void;
  onSave: (newLocation: FineCamera) => void;
  activeGroup?: FullFineCameraGroup | undefined;
  changeRoute?: (newRouteLonLat: number[][]) => void;
}) => {
  const [newRoute, setNewRoute] = useState<number[][]>([]);
  const [isNewRouteChanged, setIsNewRouteChanged] = useState(false);

  useEffect(() => {
    if (activeGroup) {
      setNewRoute(
        (
          activeGroup?.manuallyBuiltRoutePoints ||
          activeGroup?.automaticallyBuiltRoutePoints
        )?.map(
          item =>
            convertStringToCoordinates(item.coordinates ?? "")?.lontat ?? [],
        ) ?? [],
      );
    }
    if (!activeGroup) {
      setNewRoute([]);
      setIsMarker(true);
      setIsRoute(false);
    }
  }, [activeGroup, open]);

  useEffect(() => {
    if (open) {
      setIsNewRouteChanged(!!activeGroup?.manuallyBuiltRoutePoints);
    }
  }, [open]);

  const resetRoute = () => {
    setNewRoute(
      activeGroup?.automaticallyBuiltRoutePoints?.map(
        item =>
          convertStringToCoordinates(item.coordinates ?? "")?.lontat ?? [],
      ) ?? [],
    );
  };

  const [markerPosition, setMarkerPosition] = useState<
    | {
        lat: number;
        lng: number;
      }
    | undefined
  >();
  const [mapRegion, setMapRegion] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 41.6938,
    lng: 44.8015,
  });
  const [newWordLocation, setNewWordLocation] = useState("");
  const [newCoordinates, setNewCoordinates] = useState("");

  useEffect(() => {
    if (coordinates) {
      setNewCoordinates(coordinates);
      const data = coordinates.replace(/\s/g, "").split(",");
      const lat = !isNaN(Number(data[0])) ? Number(data[0]) : null;
      const lng = !isNaN(Number(data[1])) ? Number(data[1]) : null;
      if (lat && lng) {
        setMarkerPosition({ lat, lng });
        setMapRegion({ lat, lng });
      } else {
        setMarkerPosition(undefined);
      }
    }
  }, [coordinates]);

  useEffect(() => {
    if (address) {
      setNewWordLocation(address);
    }
  }, [address]);

  useEffect(() => {
    if (recognizedMaxSpeed) {
      setMaxSpeed(recognizedMaxSpeed);
    }
    if (itemRegion) {
      setRegion(itemRegion);
    }
    if (itemMunicipality) {
      setMunicipality(itemMunicipality);
    }
  }, [recognizedMaxSpeed, itemRegion, itemMunicipality]);

  useEffect(() => {
    if (open) {
      return () => {
        setNewWordLocation("");
        setPolygonCoords([]);
        setIsMarker(true);
        setMaxSpeed(0);
        setNewCoordinates("");
        setMapRegion({
          lat: 41.6938,
          lng: 44.8015,
        });
        setMarkerPosition(undefined);
      };
    } else {
      setNewRoute([]);
    }
  }, [open]);

  useEffect(() => {
    if (locationData) {
      setNewWordLocation(locationData.name);
      setMunicipality(locationData.municipality);
      setRegion(locationData.region);
      setNewCoordinates(locationData.coordinates);
      setMaxSpeed(locationData.maxSpeed ?? 0);
      setIsMarker(true);

      setType(
        locationTypes.find(({ value }) => value === locationData.type) ??
          locationTypes[0],
      );
      const data = locationData.coordinates.replace(/\s/g, "").split(",");
      const lat = !isNaN(Number(data[0])) ? Number(data[0]) : null;
      const lng = !isNaN(Number(data[1])) ? Number(data[1]) : null;

      if (lat && lng) {
        setMarkerPosition({ lat, lng });
        setMapRegion({ lat, lng });
      } else {
        setMarkerPosition(undefined);
      }

      if (locationData.cameraViewArea) {
        const coords = locationData.cameraViewArea.map(point => {
          const data = point.split(",");
          return { lat: Number(data[0]), lng: Number(data[1]) };
        });
        setPolygonCoords(coords);
      } else {
        setPolygonCoords([]);
      }
    } else {
      setNewWordLocation("");
      setPolygonCoords([]);
      setType(locationTypes[0]);
      setIsMarker(true);
      setMaxSpeed(0);
      setNewCoordinates("");
    }
  }, [locationData]);

  useEffect(() => {
    if (markerPosition) {
      setNewCoordinates(`${markerPosition.lat}, ${markerPosition.lng}`);
    }
  }, [markerPosition]);

  const addNewLocationAsync = useAsync<void, any>();

  const handleAddNewLocation = () => {
    if (addNewLocationAsync.isLoading) {
      return;
    }
    const uuid = uuidv4();
    const cameraViewArea = polygonCoords.map(item => `${item.lat},${item.lng}`);
    const newLocation = new AddFineCameraRequest({
      id: locationData ? locationData.id : uuid,
      name: newWordLocation,
      coordinates: newCoordinates,
      cameraViewArea: cameraViewArea,
      maxSpeed: maxSpeed,
      type: type.value,
      region: region,
      municipality: municipality,
    });

    if (!!coordinates) {
      return onSave(
        new FineCamera({
          ...newLocation,
          createdAt: new Date(),
          modifiedAt: new Date(),
          createdBy: "",
          modifiedBy: "",
        }),
      );
    }

    if (!locationData) {
      addNewLocationAsync.run(
        adminFinesService.addCamera(newLocation).then((e: any) => {
          if (!e) {
            onSave(
              new FineCamera({
                ...newLocation,
                createdAt: new Date(),
                modifiedAt: new Date(),
                createdBy: "",
                modifiedBy: "",
              }),
            );
            setNewWordLocation("");
            setPolygonCoords([]);
            setIsMarker(false);
            setMarkerPosition(undefined);
            setMapRegion({
              lat: 41.6938,
              lng: 44.8015,
            });
          }
        }),
      );
    } else {
      onSave(
        new FineCamera({
          ...newLocation,
          createdAt: new Date(),
          modifiedAt: new Date(),
          createdBy: "",
          modifiedBy: "",
          images: locationData.images,
        }),
      );
    }
  };

  const [type, setType] = useState(locationTypes[0]);
  const [maxSpeed, setMaxSpeed] = useState(0);
  const [region, setRegion] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [polygonCoords, setPolygonCoords] = useState<
    {
      lat: number;
      lng: number;
    }[]
  >([]);
  const [isMarker, setIsMarker] = useState(true);
  const [isRoute, setIsRoute] = useState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box
          sx={{
            ...style,
          }}
          component="section"
        >
          <div
            style={{
              width: "100%",
              height: 300,
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                height: 300,
                backgroundColor: "#000",
              }}
            >
              <ImageVideoCarousel images={images} />
            </div>
            <div style={{ flex: 1, backgroundColor: "#000" }}>
              <Streetview
                apiKey="AIzaSyAVr8gRuZGWGq18bv6Tx1TGPCA6uVhrLoc"
                streetViewPanoramaOptions={{
                  position: {
                    lat: markerPosition?.lat,
                    lng: markerPosition?.lng,
                  },
                  addressControl: true,
                  panControl: true,
                  zoomControl: true,
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <APIProvider apiKey="AIzaSyAVr8gRuZGWGq18bv6Tx1TGPCA6uVhrLoc">
              {address && (
                <MapComponent
                  address={address}
                  setMarkerPosition={setMarkerPosition}
                  setRegion={setMapRegion}
                />
              )}
              {isRoute ? (
                <>
                  <MapPolygons
                    polygons={
                      activeGroup?.cameras
                        ?.map(item =>
                          item.cameraViewArea
                            ? [
                                item.cameraViewArea?.map(
                                  coords =>
                                    convertStringToCoordinates(coords)
                                      ?.lontat ?? [],
                                ),
                              ]
                            : [],
                        )
                        ?.flat() ?? []
                    }
                  />
                  <MapRoutePoints routesPoints={[newRoute]} />
                </>
              ) : (
                <MapPolygons
                  polygons={[polygonCoords.map(item => [item.lng, item.lat])]}
                />
              )}
              <Map
                mapId={"addnewlocation"}
                onClick={e => {
                  const latlng = e.detail.latLng;
                  if (latlng) {
                    if (isMarker) {
                      setMarkerPosition(latlng);
                    } else if (polygonCoords.length < 4) {
                      setPolygonCoords(state => [...state, latlng]);
                    }
                  }
                }}
                zoom={16}
                center={mapRegion}
              >
                {isRoute && (
                  <>
                    {newRoute.map((item, index) => (
                      <AdvancedMarker
                        key={item.toString()}
                        position={{
                          lat: item[1],
                          lng: item[0],
                        }}
                        onClick={() => {
                          if (index !== 0 && index !== newRoute.length - 1) {
                            setNewRoute(s =>
                              s.filter(
                                i => i[0] !== item[0] && i[1] !== item[1],
                              ),
                            );
                            setIsNewRouteChanged(true);
                          }
                        }}
                        draggable={index !== 0 && index !== newRoute.length - 1}
                        onDragEnd={e => {
                          setNewRoute(s =>
                            s.map(i =>
                              i === item
                                ? [
                                    e.latLng?.lng() || i[0],
                                    e.latLng?.lat() || i[1],
                                  ]
                                : i,
                            ),
                          );
                          setIsNewRouteChanged(true);
                        }}
                      >
                        {index === 0 || index === newRoute.length - 1 ? (
                          <Pin
                            background={"#007FFF"}
                            borderColor={"#007FFF"}
                            glyphColor={"#000000"}
                          />
                        ) : (
                          <Pin />
                        )}
                      </AdvancedMarker>
                    ))}
                  </>
                )}
                {markerPosition && !isRoute && (
                  <AdvancedMarker position={markerPosition}>
                    <Pin />
                  </AdvancedMarker>
                )}
                {!isMarker &&
                  !isRoute &&
                  polygonCoords.map(item => (
                    <AdvancedMarker
                      key={item.lat + item.lng}
                      position={item}
                      onClick={() =>
                        setPolygonCoords(state => {
                          return state.filter(
                            i => !(i.lat === item.lat && i.lng === item.lng),
                          );
                        })
                      }
                    >
                      <Pin />
                    </AdvancedMarker>
                  ))}
              </Map>
            </APIProvider>
          </div>
          <Box
            sx={{
              background: "#fff",
              flexDirection: "row",
            }}
            component="section"
          >
            <Button
              sx={{
                color: isRoute || !isMarker ? "grey" : undefined,
                fontSize: 18,
              }}
              onClick={() => {
                setIsRoute(false);
                setIsMarker(true);
              }}
            >
              Set Marker
            </Button>
            <Button
              sx={{
                marginLeft: 1,
                color: isRoute || isMarker ? "grey" : undefined,
                fontSize: 18,
              }}
              onClick={() => {
                setIsRoute(false);
                setIsMarker(false);
              }}
            >
              Set Area
            </Button>
            {newRoute.length > 0 && (
              <Button
                sx={{
                  marginLeft: 1,
                  color: isRoute ? undefined : "grey",
                  fontSize: 18,
                }}
                onClick={() => {
                  setIsRoute(true);
                  setIsMarker(false);
                }}
              >
                Set Route
              </Button>
            )}
          </Box>
          {isRoute && activeGroup ? (
            <ChangeRouteSection
              resetRoute={resetRoute}
              isNewRouteChanged={isNewRouteChanged}
              newRoute={newRoute}
              groupKey={activeGroup.groupKey}
              changeRoute={changeRoute}
              handleClose={handleClose}
            />
          ) : (
            <InputsSection
              setIsMarker={setIsMarker}
              newWordLocation={newWordLocation}
              setNewWordLocation={setNewWordLocation}
              maxSpeed={maxSpeed}
              setMaxSpeed={setMaxSpeed}
              type={type}
              setType={setType}
              region={region}
              setRegion={setRegion}
              municipality={municipality}
              setMunicipality={setMunicipality}
              handleAddNewLocation={handleAddNewLocation}
              isLoading={addNewLocationAsync.isLoading}
              newCoordinates={newCoordinates}
              setNewCoordinates={setNewCoordinates}
              setMarkerPosition={setMarkerPosition}
              setMapRegion={setMapRegion}
              handleClose={handleClose}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  display: "flex",
  flexDirection: "column",
  height: "98%",
  minWidth: 1126,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default memo(AddNewLocationModal);
