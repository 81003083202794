import { useState } from "react";

import useAsync from "hooks/useAsync";
import { adminCarModelsService } from "services/adminCarModelsService";
import { ApiError, FileResponse } from "services/borbalo-main.service";

export const useLoad3dModel = () => {
  const async = useAsync<FileResponse, ApiError>();
  const [file, setFile] = useState<File | null>(null);

  const load3dModel = async (name: string) => {
    const response = await async.run(adminCarModelsService.download3d(name));
    if (response?.data) {
      const file = new File([response.data], response.fileName ?? "model.fbx");
      setFile(file);
    }
  };

  return {
    is3dModelLoading: async.isLoading,
    load3dModel,
    file,
  };
};
