import {
  Box,
  Button,
  InputLabel,
  Modal,
  TextareaAutosize,
} from "@mui/material";
import { useEffect, useState } from "react";

const RenameLocationModal = ({
  isOpen,
  toggleModal,
  isButtonDisabled,
  handleRenameClick,
}: {
  isOpen?: string | null;
  toggleModal: () => void;
  isButtonDisabled?: boolean;
  handleRenameClick: (newLocation: string) => void;
}) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (!isOpen) {
      setText("");
    } else {
      setText(isOpen);
    }
  }, [isOpen]);

  const handleAddDescription = () => {
    if (isOpen) {
      handleRenameClick(text);
    }
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box
        sx={{
          ...style,
        }}
        component="section"
      >
        <InputLabel>New Location</InputLabel>
        <TextareaAutosize
          value={text}
          onChange={e => setText(e.target.value)}
          style={{
            maxWidth: window.innerWidth - 200,
            minWidth: window.innerWidth - 200,
            minHeight: 150,
            padding: 4,
            marginBottom: 16,
            borderWidth: 1.01,
            borderRadius: 3,
            outlineColor: "#00c6be",
          }}
        />
        <Button
          sx={{
            width: "100%",
            mb: 1.6,
          }}
          onClick={handleAddDescription}
          disabled={isButtonDisabled}
        >
          {"Change Rename"}
        </Button>
        <Button
          sx={{
            width: "100%",
          }}
          onClick={toggleModal}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  maxHeight: window.innerHeight - 50,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  p: 4,
};

export default RenameLocationModal;
