import React from "react";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { useCallback, useEffect, useMemo, useState } from "react";
import uniqueBy from "lodash.uniqby";
import useAsync from "hooks/useAsync";
import {
  FineCamera,
  FineCameraGroupAutomaticallyBuiltRoute,
  FineCameraRoutePoint,
  FineCameraType,
  FullFineCameraGroup,
  PagedListOfFineCamera,
  PagedListOfFineCameraGroupAutomaticallyBuiltRoute,
  PagedListOfFullFineCameraGroup,
  UpdateFineCameraRequest,
} from "services/borbalo-main.service";
import { adminFinesService } from "services/fines";
import AddNewLocationModal from "components/AddNewLocationModal";
import moment from "moment";
import { IconChevronDown } from "pages/Fines/assets/IconChevron";
import clsx from "clsx";
import { useSearch } from "components/SearchComponent/useSearch";
import Viewer from "pages/Fines/Viewer";
import AllCamerasOnMap from "components/AllCamerasOnMap";
import useModal from "hooks/useModal";
import { useNavigate } from "react-router-dom";
import { RequestErrors } from "store/error/slice";

type HeaderItem = {
  title: string;
  field?: keyof FineCamera | undefined | "groupKey";
  isGroup?: boolean;
};

export const locationTypes: {
  value: FineCameraType;
  label: string;
}[] = [
  {
    value: FineCameraType.Interception,
    label: "კვეთა",
  },
  {
    value: FineCameraType.Street,
    label: "ქუჩა",
  },
  {
    value: FineCameraType.Highway,
    label: "ავტობანი",
  },
];

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  text: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
  },
  tableCell: {
    maxWidth: 200,
    borderStyle: "border-box",
  },
  group: { top: -50, position: "relative" },
});

const FinesLocations = () => {
  const navigate = useNavigate();
  const allCamerasModal = useModal();
  const addNewCamera = useModal();
  const [isShowAll, setIsShowAll] = useState(true);
  const [activeKey, setActiveKey] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState<
    FineCamera | undefined
  >();
  const [editIndex, setEditIndex] = useState<string | undefined>();
  const classes = useStyles();
  const allDeletedCamerasAsync = useAsync<PagedListOfFineCamera, any>();
  const [allActiveCameras, setAllActiveCameras] = useState<
    FullFineCameraGroup[]
  >([]);
  const [allDeletedLocations, setAllDeletedLocations] = useState<FineCamera[]>(
    [],
  );
  const updateLocationAsync = useAsync<
    PagedListOfFineCameraGroupAutomaticallyBuiltRoute,
    any
  >();
  const deleteLocationAsync = useAsync<void, any>();
  const restoreCameraAsync = useAsync<FullFineCameraGroup, any>();
  const [isActiveCameras, setIsActiveCameras] = useState(true);
  const { search, SearchComponent } = useSearch();
  const allCamerasAsync = useAsync<PagedListOfFullFineCameraGroup, any>();
  const [activeGroup, setActiveGroup] = useState<
    FullFineCameraGroup | undefined
  >();
  const [isViewerOpen, setIsViewerOpen] = useState<string | undefined>();
  const openImageViewer = useCallback((cameraId?: string) => {
    setIsViewerOpen(cameraId);
  }, []);
  const closeImageViewer = () => {
    setIsViewerOpen(undefined);
  };

  const [sortData, setSortData] = useState<{
    field: keyof FineCamera | "groupKey";
    isGroup?: boolean;
    sortType: "desc" | "asc";
  }>({
    sortType: "desc",
    field: "groupKey",
    isGroup: true,
  });

  const header: HeaderItem[] = [
    { title: "Pictures" },
    { title: "Group Key", field: "groupKey", isGroup: true },
    { title: "Region", field: "region" },
    { title: "municipality", field: "municipality" },
    { title: "Fine corrected camera", field: "name" },
    { title: "Camera Area" },
    { title: "Max Speed", field: "maxSpeed" },
    { title: "Creator", field: "createdBy" },
    { title: "Creation Date", field: "createdAt" },
    { title: "Last Editor", field: "modifiedBy" },
    { title: "Last Edit Date", field: "modifiedAt" },
    { title: "Type", field: "type" },
    { title: "" },
    ...(isActiveCameras ? [{ title: "" }] : []),
  ];

  const onHeaderClick = (item: HeaderItem, index: number) => {
    setSortData(({ sortType, field }) => {
      if (!item.field) {
        return sortData;
      }
      if (field === item.field) {
        return {
          sortType: sortType === "asc" ? "desc" : "asc",
          field: item.field,
          isGroup: item.isGroup,
        };
      }

      return {
        sortType: "desc",
        field: item.field,
        isGroup: item.isGroup,
      };
    });
  };

  useEffect(() => {
    setAllActiveCameras(allCamerasAsync.data?.entities ?? []);
  }, [allCamerasAsync.data]);

  useEffect(() => {
    setAllDeletedLocations(allDeletedCamerasAsync.data?.entities ?? []);
  }, [allDeletedCamerasAsync.data]);

  const handleAddNewLocation = (newLocation: FineCamera) => {
    allCamerasAsync.run(adminFinesService.cameras());

    setEditIndex(undefined);
    addNewCamera.handleCloseModal();
  };

  const handleEditClick = (id: string) => {
    setEditIndex(id);
  };

  const handleDeleteClick = (camera: FineCamera) => {
    const id = camera.id;
    deleteLocationAsync.run(adminFinesService.removeCamera(id)).then(e => {
      if (!e) {
        setAllDeletedLocations(s => [camera, ...s]);
        setAllActiveCameras(s =>
          s
            .map(
              group =>
                new FullFineCameraGroup({
                  ...group,
                  cameras: group.cameras.filter(item => item.id !== id),
                }),
            )
            .filter(group => group.cameras.length > 0),
        );
      }
    });
  };

  const handleRestoreCamera = (camera: FineCamera) => {
    const id = camera.id;
    restoreCameraAsync
      .run(adminFinesService.recoverCamera(camera.id))
      .then(() => {
        setAllDeletedLocations(s => s.filter(item => item.id !== id));
      });
  };

  useEffect(() => {
    const data = restoreCameraAsync.data;
    if (data) {
      setAllActiveCameras(s => [data, ...s]);
    }
  }, [restoreCameraAsync.data]);

  const changeRoute = (newRouteLonLat: number[][]) => {
    setAllActiveCameras(s =>
      s.map(group =>
        group.groupKey === activeGroup?.groupKey
          ? new FullFineCameraGroup({
              ...group,
              manuallyBuiltRoutePoints: newRouteLonLat.map(
                item =>
                  new FineCameraRoutePoint({
                    coordinates: `${item[1]},${item[0]}`,
                    address: "",
                  }),
              ),
            })
          : group,
      ),
    );
  };

  const handleSaveClick = (newLocation: FineCamera, id: string) => {
    updateLocationAsync
      .run(
        adminFinesService.updateCamera(
          id,
          new UpdateFineCameraRequest({
            name: newLocation.name!,
            coordinates: newLocation.coordinates!,
            cameraViewArea: newLocation.cameraViewArea,
            maxSpeed: newLocation.maxSpeed,
            type: newLocation.type,
            region: newLocation.region,
            municipality: newLocation.municipality,
          }),
        ),
      )
      .then(
        (
          value:
            | PagedListOfFineCameraGroupAutomaticallyBuiltRoute
            | { payload: RequestErrors | null; type: "error/toggleError" }
            | undefined,
        ) => {
          // @ts-ignore
          if (!value || !!value?.payload) {
            return;
          }

          // @ts-ignore
          const data = (value?.entities ??
            []) as FineCameraGroupAutomaticallyBuiltRoute[];
          setAllActiveCameras(s =>
            s.map(group => {
              const changedGroup = data.find(
                item => item.groupKey === group.groupKey,
              );
              if (changedGroup) {
                return new FullFineCameraGroup({
                  ...group,
                  automaticallyBuiltRoutePoints:
                    changedGroup.automaticallyBuiltRoutePoints,
                  manuallyBuiltRoutePoints: undefined,
                  cameras: group.cameras.map(item =>
                    item.id === id
                      ? new FineCamera({ ...item, ...newLocation })
                      : item,
                  ),
                });
              } else {
                return group;
              }
            }),
          );
          setEditIndex(undefined);
        },
      );
  };

  useEffect(() => {
    allCamerasAsync.run(adminFinesService.cameras());
    allDeletedCamerasAsync.run(adminFinesService.removedCameras(1, 10000));
  }, []);

  const filterFunction = useCallback(
    (item: FineCamera) =>
      (isShowAll ? true : !item.cameraViewArea?.length) &&
      (item.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.region?.toLowerCase().includes(search.toLowerCase()) ||
        item.municipality?.toLowerCase().includes(search.toLowerCase()) ||
        item.maxSpeed
          ?.toString()
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        item.createdBy?.toLowerCase().includes(search.toLowerCase()) ||
        item.modifiedBy?.toLowerCase().includes(search.toLowerCase()) ||
        item.type?.toLowerCase().includes(search.toLowerCase())),
    [search, isShowAll],
  );

  const mapTableData = useCallback(
    (item: FineCamera, groupKey: string) => [
      groupKey?.slice(0, 6) ?? "-",
      item.region ?? "-",
      item.municipality ?? "-",
      item.name ?? "",
      item.cameraViewArea?.length ? "Area set" : "-",
      item.maxSpeed ? item.maxSpeed.toString() : "-",
      item.createdBy ?? "-",
      item.createdAt
        ? moment(item.createdAt).format("YYYY MM DD, HH:mm:ss")
        : "-",
      item.modifiedBy ?? "-",
      item.modifiedAt
        ? moment(item.modifiedAt).format("YYYY MM DD, HH:mm:ss")
        : "-",
      locationTypes.find(({ value }) => value === item.type)?.label ?? "-",
    ],
    [sortData, search, isShowAll],
  );

  const isShowGroupedTable =
    sortData.isGroup && isShowAll && !search && isActiveCameras;

  const tableDataGrouped = useMemo(() => {
    if (isShowGroupedTable) {
      return allActiveCameras.slice().sort((a, b) => {
        const condition =
          sortData.sortType === "desc"
            ? a.groupKey < b.groupKey
            : a.groupKey > b.groupKey;

        return condition ? 1 : -1;
      });
    }

    return [];
  }, [allActiveCameras, sortData, isShowGroupedTable, search]);

  const tableData = useMemo(() => {
    const sortField = sortData.field;

    if (!isActiveCameras) {
      return allDeletedLocations
        .slice()
        .sort((a, b) => {
          if (sortField !== "groupKey") {
            const condition =
              sortData.sortType === "desc"
                ? a[sortField]! < b[sortField]!
                : a[sortField]! > b[sortField]!;

            return condition ? 1 : -1;
          }
          return 0;
        })
        .filter(filterFunction);
    }

    if (!isShowGroupedTable) {
      return uniqueBy(
        // @ts-ignore
        allActiveCameras
          .slice()
          .sort((a, b) => {
            if (sortField !== "groupKey") {
              const condition =
                sortData.sortType === "desc"
                  ? a.cameras[0][sortField]! < b.cameras[0][sortField]!
                  : a.cameras[0][sortField]! > b.cameras[0][sortField]!;

              return condition ? 1 : -1;
            }

            return 0;
          })

          .map(({ cameras, groupKey }) =>
            cameras.map(item => ({
              ...item,
              groupKey,
            })),
          )
          .flat(),
        (item: FineCamera) => item.name,
      ).filter(filterFunction);
    }

    return [];
  }, [allActiveCameras, sortData, isShowGroupedTable, search, isActiveCameras]);

  const allCameras = isActiveCameras
    ? allActiveCameras.map(item => item.cameras).flat()
    : allDeletedLocations;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
        component="section"
      >
        <Typography variant="h3" mb={1.6}>
          List of {isActiveCameras ? "Active" : "Removed"} Cameras
        </Typography>
        <Button onClick={() => navigate("/fines")}>List of Fines</Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          mb: 3,
        }}
        component="section"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
          component="section"
        >
          <Typography variant="h4" mb={1.6}>
            Total {tableDataGrouped.length || tableData.length}
          </Typography>
          {SearchComponent}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
          component="section"
        >
          <Button
            disabled={!isActiveCameras}
            onClick={addNewCamera.handleOpenModal}
          >
            Add new Camera
          </Button>
          <Button onClick={() => setIsActiveCameras(prevState => !prevState)}>
            {isActiveCameras ? "Removed" : "Active"} Cameras
          </Button>
          <Button onClick={allCamerasModal.handleOpenModal}>
            All Cameras on Map
          </Button>
          <Button
            onClick={() => setIsShowAll(s => !s)}
            disabled={!isActiveCameras}
          >
            {isShowAll ? "Hide " : "Show "}
            Cameras with Area
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="my table">
          <TableHead>
            <TableRow>
              {header.map((headerItem, index) => (
                <TableCell
                  key={headerItem.title + index}
                  onClick={() => onHeaderClick(headerItem, index)}
                >
                  {
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        color: !headerItem.field ? "grey" : undefined,
                      }}
                      component="section"
                    >
                      {headerItem.title}

                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          marginLeft: 0.8,
                          alignItems: "center",
                        }}
                        component="section"
                      >
                        {sortData.field === headerItem.field && (
                          <IconChevronDown
                            size={16}
                            className={
                              (clsx("color-grey"),
                              sortData.sortType === "asc" ? "flip" : undefined)
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isShowGroupedTable ? (
              tableDataGrouped.map(groupItem =>
                groupItem.cameras.map((camera, cameraIndex) => {
                  const cells = mapTableData(camera, groupItem.groupKey);

                  return (
                    <TableRow
                      key={groupItem.groupKey + camera.id}
                      onClick={() => setActiveKey(camera.id)}
                      style={{
                        backgroundColor:
                          camera.id === activeKey
                            ? "rgba(0, 198, 190, 0.1)"
                            : "white",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderWidth:
                            cameraIndex === 0 && groupItem.cameras.length > 1
                              ? 0
                              : 1,
                        }}
                      >
                        <Button
                          onClick={() => openImageViewer(camera.id)}
                          disabled={!camera.images?.length}
                        >
                          Show media
                        </Button>
                      </TableCell>
                      <>
                        {cells.map((text, cellIndex) => {
                          const isCustomStyle =
                            cellIndex === 0 &&
                            cameraIndex === 0 &&
                            groupItem.cameras.length > 1;
                          return (
                            <TableCell
                              key={
                                groupItem.groupKey +
                                camera.id +
                                text +
                                cellIndex
                              }
                              component="th"
                              scope="row"
                              style={
                                isCustomStyle
                                  ? {
                                      borderWidth: 0,
                                    }
                                  : {}
                              }
                            >
                              <div
                                style={
                                  isCustomStyle
                                    ? {
                                        top: 40,
                                        position: "relative",
                                      }
                                    : {}
                                }
                              >
                                {cellIndex === 0 && cameraIndex === 1
                                  ? ""
                                  : text}
                              </div>
                            </TableCell>
                          );
                        })}
                      </>
                      <>
                        {isActiveCameras ? (
                          <>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  if (groupItem.cameras.length > 1) {
                                    setActiveGroup(groupItem);
                                  } else {
                                    setActiveGroup(undefined);
                                  }
                                  handleEditClick(camera.id);
                                }}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>

                            <TableCell>
                              <Button
                                onClick={() => setDeleteModalOpen(camera)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell>
                            <Button onClick={() => handleRestoreCamera(camera)}>
                              <RestoreIcon />
                            </Button>
                          </TableCell>
                        )}
                        {camera.id === isViewerOpen && (
                          <Viewer
                            images={camera?.images}
                            closeImageViewer={closeImageViewer}
                          />
                        )}
                      </>
                    </TableRow>
                  );
                }),
              )
            ) : (
              <>
                {tableData.map((camera, index) => {
                  // @ts-ignore
                  const cells = mapTableData(camera, camera.groupKey);
                  return (
                    <TableRow
                      key={camera.id}
                      onClick={() => setActiveKey(camera.id)}
                      style={{
                        backgroundColor:
                          camera.id === activeKey
                            ? "rgba(0, 198, 190, 0.1)"
                            : "white",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Button
                          onClick={() => openImageViewer(camera.id)}
                          disabled={!camera.images?.length}
                        >
                          Show media
                        </Button>
                      </TableCell>
                      <>
                        {cells.map((text, cellIndex) => (
                          <TableCell
                            key={camera.id + text + cellIndex}
                            component="th"
                            scope="row"
                          >
                            {text}
                          </TableCell>
                        ))}
                      </>
                      <>
                        {isActiveCameras ? (
                          <>
                            <TableCell>
                              <Button
                                onClick={() => handleEditClick(camera.id)}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>

                            <TableCell>
                              <Button
                                onClick={() => setDeleteModalOpen(camera)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell>
                            <Button onClick={() => handleRestoreCamera(camera)}>
                              <RestoreIcon />
                            </Button>
                          </TableCell>
                        )}
                        {camera.id === isViewerOpen && (
                          <Viewer
                            images={camera?.images}
                            closeImageViewer={closeImageViewer}
                          />
                        )}
                      </>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddNewLocationModal
        open={addNewCamera.open}
        handleClose={addNewCamera.handleCloseModal}
        onSave={(newLocation: FineCamera) => {
          handleAddNewLocation(newLocation);
        }}
      />
      <AddNewLocationModal
        open={editIndex !== undefined}
        handleClose={() => setEditIndex(undefined)}
        locationData={allCameras.find(({ id }) => id === editIndex)}
        images={allCameras.find(({ id }) => id === editIndex)?.images}
        onSave={(newLocation: FineCamera) => {
          if (editIndex !== undefined) {
            handleSaveClick(newLocation, editIndex);
          }
        }}
        activeGroup={activeGroup}
        changeRoute={changeRoute}
      />
      <Modal
        open={deleteModalOpen !== undefined}
        onClose={() => setDeleteModalOpen(undefined)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box
          sx={{
            ...style,
          }}
          component="section"
        >
          <Typography variant="h6" mb={1.6}>
            Delete "{deleteModalOpen !== undefined ? deleteModalOpen.name : ""}"
            loctation?
          </Typography>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "skyBlue",
              mb: 1.6,
            }}
            onClick={() => {
              if (deleteModalOpen !== undefined) {
                handleDeleteClick(deleteModalOpen);
                setDeleteModalOpen(undefined);
              }
            }}
          >
            Yes
          </Button>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "skyBlue",
            }}
            onClick={() => setDeleteModalOpen(undefined)}
          >
            No
          </Button>
        </Box>
      </Modal>
      <AllCamerasOnMap
        open={allCamerasModal.open}
        handleCloseModal={allCamerasModal.handleCloseModal}
        handleEditClick={handleEditClick}
        setActiveGroup={setActiveGroup}
        allCameras={allActiveCameras}
      />
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default React.memo(FinesLocations);
