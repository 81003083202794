import jwt_decode, { JwtPayload } from "jwt-decode";

type TokenProps = {
  name: string;
  preferred_username: string;
  roles: string[];
  azp: string;
};

export class TokensModel {
  constructor(
    public access_token: string,
    public token_type: string,
    public expires_in: number,
    public refresh_expires_in: number,
    public refresh_token: string,
    public scope: string,
    public session_state: string,
    public access_token_expire_time: number,
  ) {}

  get accessToken() {
    return `${this.token_type} ${this.access_token}`;
  }

  get refreshToken() {
    return this.refresh_token;
  }

  get azp() {
    const parcesAccessToken: JwtPayload & TokenProps = jwt_decode(
      this.refresh_token,
    );

    return parcesAccessToken.azp;
  }

  get tokenInfo() {
    const parcesAccessToken: JwtPayload & TokenProps = jwt_decode(
      this.access_token,
    );

    return {
      email: parcesAccessToken.preferred_username,
      name: parcesAccessToken.name,
    };
  }

  get roles() {
    const parcesAccessToken: JwtPayload & TokenProps = jwt_decode(
      this.access_token,
    );

    return parcesAccessToken.roles;
  }

  isAccessTokenExpired() {
    return (
      !this.access_token_expire_time ||
      Number(this.access_token_expire_time) <=
        Math.ceil(new Date().getTime() / 1000) + 30
    );
  }

  isRefreshTokenExpired() {
    if (!this.refresh_token) {
      return localStorage.removeItem("auth");
    }

    const parcesRefreshToken: JwtPayload = jwt_decode(this.refresh_token);

    const isTokenExpired =
      parcesRefreshToken?.exp &&
      Number(parcesRefreshToken?.exp) <=
        Math.ceil(new Date().getTime() / 1000) + 30;

    if (isTokenExpired) {
      localStorage.removeItem("auth");
    }

    return isTokenExpired;
  }
}
