import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import {
  DiscountProgramBrandLocation,
  DiscountProgramState,
  PagedListOfDiscountProgramBrand,
  PagedListOfDiscountProgramBrandLocation,
} from "services/borbalo-main.service";
import { useNavigate, useParams } from "react-router-dom";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import StateComponent from "components/StateComponent";
import PreviewModal from "pages/ProvidersBrands/PreviewModal";
import { LocationPreview } from "pages/ProvidersBrands/Previews/LocationPreview";
import { usePreviewLanguage } from "pages/ProvidersBrands/usePreviewLanguage";

const ProvidersBrandsLocations = () => {
  const navigate = useNavigate();
  const [isOpenLocationState, setIsOpenLocationState] = useState<
    DiscountProgramBrandLocation | undefined
  >(undefined);
  const { previewLanguage, SelectComponent, isEn } = usePreviewLanguage();
  const { id, brandId } = useParams();
  const { data, isLoading, run } = useAsync<
    PagedListOfDiscountProgramBrand,
    any
  >();
  const changeStateAsync = useAsync<void, any>();
  const [loadingId, setLoadingId] = useState<string | undefined>();
  const locationsAsync = useAsync<
    PagedListOfDiscountProgramBrandLocation,
    any
  >();

  const currentBrand = data?.entities?.find(brand => brand.id === brandId);

  const getAllBrands = () => {
    if (id) {
      run(adminDiscountProgramService.getBrandsOfProvider(id));
    }
  };

  const getAllLocations = () => {
    if (id) {
      locationsAsync.run(
        adminDiscountProgramService.getLocationsOfProvider(id),
      );
    }
  };

  useEffect(() => {
    getAllLocations();
    getAllBrands();
  }, [id]);

  useEffect(() => {
    if (changeStateAsync.isSuccess) {
      setLoadingId(undefined);
      setIsOpenLocationState(undefined);
      getAllLocations();
    }
  }, [changeStateAsync.isSuccess]);

  useEffect(() => {
    if (changeStateAsync.isError) {
      setLoadingId(undefined);
    }
  }, [changeStateAsync.isError]);

  const stateChange = (locationId: string, state: DiscountProgramState) => {
    setLoadingId(brandId);
    changeStateAsync.run(
      adminDiscountProgramService.setStateOfLocation(locationId, state),
    );
  };

  const originalIds =
    locationsAsync.data?.entities
      ?.map(item => item.originalLocationId ?? "")
      .filter(item => item) ?? [];

  const locations =
    locationsAsync.data?.entities?.filter(
      ({ id, brandId }) =>
        !originalIds.includes(id) &&
        currentBrand &&
        (brandId === currentBrand.id ||
          brandId === currentBrand.originalBrandId),
    ) ?? [];

  const ActivateButton = ({ item }: { item?: DiscountProgramBrandLocation }) =>
    item ? (
      <Button
        disabled={
          item.state === DiscountProgramState.Active || loadingId === item.id
        }
        onClick={() => stateChange(item.id, DiscountProgramState.Active)}
      >
        Activate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === item.id),
          }}
        />
      </Button>
    ) : null;

  const DeactivateButton = ({
    item,
  }: {
    item?: DiscountProgramBrandLocation;
  }) =>
    item ? (
      <Button
        disabled={
          item.state === DiscountProgramState.Deactivated ||
          loadingId === item.id
        }
        onClick={() => stateChange(item.id, DiscountProgramState.Deactivated)}
      >
        Deactivate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === item.id),
          }}
        />
      </Button>
    ) : null;

  const dataInfo =
    locations?.map(item => {
      return [
        previewLanguage === "en" ? item.locationNameEn : item.locationNameGe,
        isEn ? item.addressEn : item.addressGe,
        isEn ? item.cityEn : item.cityGe,
        <StateComponent state={item.state} marginBottom={1} />,
        <ActivateButton item={item} />,
        <DeactivateButton item={item} />,
        <Button onClick={() => setIsOpenLocationState(item)}>
          Show Preview
        </Button>,
      ];
    }) ?? [];

  const tableData = [
    ["Location Name", "Address", "City", "State", "", "", ""],
    ...dataInfo,
  ];

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        component="section"
      >
        <Typography variant="h3" mb={1.6}>
          <button
            style={{
              color: "black",
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/discount-program-providers/${id}`)}
          >
            <Typography variant="h3">{"<"}</Typography>
          </button>
          {` ${
            isEn
              ? `"${currentBrand?.enTitle || ""}"`
              : `"${currentBrand?.geTitle || ""}"`
          } Locations`}
          {isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
        {SelectComponent}
      </Box>
      <Typography variant="h4" mb={1.6}>
        Total {locations?.length}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} />
      <PreviewModal
        isOpen={!!isOpenLocationState}
        toggleModal={() => setIsOpenLocationState(undefined)}
        buttons={
          <>
            <ActivateButton item={isOpenLocationState} />
            <DeactivateButton item={isOpenLocationState} />
          </>
        }
      >
        {isOpenLocationState && (
          <>
            {isOpenLocationState && (
              <>
                {isOpenLocationState.originalLocationId && (
                  <div className="column-align-center">
                    <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                      Active State
                    </Typography>
                    <LocationPreview
                      brand={currentBrand}
                      language={previewLanguage}
                      location={locationsAsync.data?.entities?.find(
                        item =>
                          item.id === isOpenLocationState.originalLocationId,
                      )}
                    />
                  </div>
                )}
                <div className="column-align-center">
                  {isOpenLocationState.state !==
                    DiscountProgramState.Active && (
                    <Typography variant="h6" sx={{ color: "#f9b60a", mb: 1 }}>
                      On Moderation
                    </Typography>
                  )}
                  <LocationPreview
                    brand={currentBrand}
                    language={previewLanguage}
                    location={isOpenLocationState}
                  />
                </div>
              </>
            )}
          </>
        )}
      </PreviewModal>
    </>
  );
};

export default ProvidersBrandsLocations;
